



















































































































































































import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'privacypolicy',
  components: {}
})
export default class extends Vue {}
